<template>
  <WeContainer v-if="ready" card="">
    <WeCard class="mb-3 sticky-top shadow-sm">
      <div class="row align-items-center">
        <div class="col">
          <!-- Title -->
          <h5 class="mb-0">
            <i class="fas fa-credit-card mr-2"></i> {{ pos.bank.name }}
            <span v-if="pos.credit_card">({{ pos.credit_card }})</span> - Sanal
            Pos
          </h5>
          <!-- Title -->
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <WeSubmitButton
            parent-class="text-center"
            v-bind:submit-status="submit"
            v-bind:update="$route.params.id"
            v-on:submit="updatePos"
          />
          <!-- ./Submit -->
        </div>
      </div>
    </WeCard>

    <WeCard>
      <!-- Is Default -->
      <div class="row align-items-center my-4">
        <!-- Label -->
        <div class="col-12 col-lg-2">
          <span class="custom-label">Ana Pos</span>
        </div>
        <!-- Label -->

        <!-- Value -->
        <div class="col-12 col-lg-4">
          <WeSwitch v-model="pos.is_default" v-bind:show-status="true" />
        </div>
        <!-- Value -->
      </div>
      <!-- ./Is Default -->

      <!-- Is Active -->
      <div class="row align-items-center my-4">
        <!-- Label -->
        <div class="col-12 col-lg-2">
          <span class="custom-label">Durum</span>
        </div>
        <!-- Label -->

        <!-- Value -->
        <div class="col-12 col-lg-4">
          <WeSwitch v-model="pos.is_active" v-bind:show-status="true" />
        </div>
        <!-- Value -->
      </div>
      <!-- ./Is Active -->

      <!-- Is Test -->
      <div class="row align-items-center my-4">
        <!-- Label -->
        <div class="col-12 col-lg-2">
          <span class="custom-label">Test Modu</span>
        </div>
        <!-- Label -->

        <!-- Value -->
        <div class="col-12 col-lg-4">
          <WeSwitch v-model="pos.is_test" v-bind:show-status="true" />
        </div>
        <!-- Value -->
      </div>
      <!-- ./Is Test -->

      <!-- Content -->
      <div
        v-for="(el, i) in getPosElements"
        v-bind:key="i"
        class="row align-items-center mb-3"
      >
        <!-- Label -->
        <div class="col-12 col-lg-2">
          <span class="custom-label">{{ el.label }}</span>
          <span class="text-danger ml-1" v-if="el.required">*</span>
          <i
            class="fas fa-question-circle ml-2"
            v-if="el.hint"
            v-tooltip.top="el.hint"
          ></i>
        </div>
        <!-- Label -->

        <!-- Value -->
        <div class="col-12 col-lg-4">
          <WeInput
            class="mb-0"
            v-if="el.form == 'input'"
            v-bind:prepend="el.prepend"
            v-bind:type="el.type"
            v-bind:filter-number="el.type == 'currency'"
            v-bind:error="$v.pos.elements.$each[i].value.$error"
            v-model="el.value"
          />
          <v-select
            label="name"
            v-if="el.form == 'select'"
            v-bind:options="getOptions(el)"
            v-bind:reduce="(o) => o.id"
            v-model="el.value"
            v-bind:placeholder="el.label"
            v-bind:class="{
              'v-select--error': $v.pos.elements.$each[i].value.$error,
            }"
            size="lg"
          ></v-select>
        </div>
        <!-- Value -->
      </div>

      <!-- Use Installment -->
      <div class="row align-items-center my-4">
        <!-- Label -->
        <div class="col-12 col-lg-2">
          <span class="custom-label">Taksit Seçenekleri</span>
        </div>
        <!-- Label -->

        <!-- Value -->
        <div class="col-12 col-lg-4">
          <WeSwitch v-model="pos.use_installment" v-bind:show-status="true" />
        </div>
        <!-- Value -->
      </div>
      <!-- ./Use Installment -->

      <div v-if="pos.use_installment">
        <!-- Seperator -->
        <div class="my-5">
          <WeDivSeperator text="Taksit Seçenekleri" />
        </div>
        <!-- ./Seperator -->

        <div class="row align-items-center mb-3">
          <!-- Label -->
          <div class="col-12 col-lg-2">
            <span class="custom-label">Minimum Sepet Tutarı</span>
          </div>
          <!-- Label -->

          <!-- Value -->
          <div class="col-12 col-lg-4">
            <WeInput class="mb-0" v-model="pos.min_installment_amount" />
          </div>
          <!-- Value -->
        </div>

        <!-- Rate Info -->
        <WeCard class="alert-primary mb-3">
          <div class="row align-items-center">
            <div class="col-auto">
              <i class="fas fa-info-circle fa-lg"></i>
            </div>
            <div class="col">
              <b>Oran Açıklamaları</b>
              <ul class="m-0">
                <li>0 değeri girildiğinde <b>Peşin</b> fiyatı tabloda gösterilecektir.</li>
                <li>1 veya daha büyük değerler için sepet tutarı üzerinden % olarak hesaplama yapılacaktır.</li>
              </ul>
            </div>
          </div>
        </WeCard>
        <!-- ./Rate Info -->

        <!-- Rates -->
        <div class="row">
          <div class="col-12 col-lg-2">
            <span class="custom-label">Oranlar (%)</span>
          </div>
          <div class="col">
            <div class="row">
              <div
                class="col-2"
                v-for="rates in pos.rates"
                v-bind:key="rates.installment"
              >
                <WeInput
                  name="rate"
                  v-model="rates.rate"
                  v-bind:prepend="rates.installment"
                  v-bind:small-size="true"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Rates -->
      </div>
    </WeCard>

    <!-- Content -->
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { requiredIf } from "vuelidate/lib/validators";

export default {
  name: "Pos",
  data() {
    return {
      ready: false,
      submit: false,
      pos: {},
    };
  },
  validations: {
    pos: {
      elements: {
        $each: {
          value: {
            required: requiredIf(function (data) {
              return data.required && !data.value;
            }),
          },
        },
      },
    },
  },
  methods: {
    ...mapActions("pos", ["update", "getById", "getList", "fetchOption"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    getPosById() {
      const id = this.$route.params.id;
      if (id) {
        this.getById({
          id: id,
          onSuccess: (result) => {
            this.pos = result;
          },
          onFinish: () => {
            this.ready = true;
          },
        });
      } else {
        this.$router.push("/payments");
      }
      this.$v.$reset();
    },
    updatePos() {
      if (!this.validateForm()) return;

      this.submit = true;
      this.update({
        id: this.$route.params.id,
        form: this.pos,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showAlert(result, this.redirectToList);
          } else {
            this.showAlert(result);
          }

          this.submit = false;
        },
      });
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    redirectToList() {
      this.$router.push("/payments");
    },
    getOptions(pos) {
      if (pos.options) {
        if (pos.options.length) {
          return pos.options;
        } else if (pos.route && pos.route.length) {
          this.fetchOption({
            route: pos.route,
            onSuccess: (result) => {
              pos.options = result.data.items;
            },
          });
        }
      }
    },
  },
  computed: {
    ...mapState(["shared"]),
    getPosElements() {
      return this.pos.elements.filter((item) => item.name !== "minInstallment");
    },
  },
  mounted() {
    this.getPosById();
  },
};
</script>
